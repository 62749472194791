import React from "react";
import PropTypes from "prop-types";
import Footer from "./Footer";
import { Link } from "gatsby";
import Logo from "../components/Logo";
import { AuthProvider } from "../components/AuthProvider";
import ProtectedLink from '../components/ProtectedLink'
import { isIE } from 'react-device-detect';
import config from '../config'
import UnsupportedBrowser from '../components/UnsupportedBrowser'


const Layout = ({ children, location, showHeader, hideFooter }) => {

  if(isIE){
    return (
        <UnsupportedBrowser/>
    )
  }
 
  return (
    <AuthProvider>
        <a
        className="sr-only sr-only-focusable w-full block bg-brand-500 text-white text-center focus:outline-none m-1 p-2 focus:ring-1 focus:ring-offset-1 focus:ring-brand-500"
        href="#main-content"
      >
        Skip to main content
      </a>
   

    {/* {showHeader ? <div className="max-w-7xl mx-auto px-4 md:px-6" id="top">
      <div className="py-4 flex justify-between items-center">
       <div className="w-40 md:w-52">
                <Link
                  to={"/account/dashboard"}
                  className="block focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-brand-500"
                >
                  <Logo fill="#00659e" includeTitle={false} />
                </Link>
              </div>
       
      </div>    </div> : null} */}

      



    <main id="main-content">{children}</main>
    {!hideFooter ? <Footer /> : null}
  </AuthProvider>
  )
}

 

Layout.propTypes = {
  children: PropTypes.node.isRequired,
};

export default Layout;
