import React from 'react';
import { Link } from "gatsby"
import { useLocalStorage } from '../hooks/useLocalStorage';
  
function classNames(...classes) {
    return classes.filter(Boolean).join(' ')
  }
  

function Navbar({location, hasEventEnded}) {
  const [eventEnded] =    useLocalStorage('hasEventEnded', '')

    const navigation = [
        { name: 'Home', href: '/#top', current: location && location.pathname == ('/') && !location.hash || location.hash.includes('top'), useHashLink: true},
        { name: 'Classes', href: '/#schedule', current: location && location.hash.includes('schedule'), useHashLink: true},
        { name: 'Ask the Experts', href: '/#ask-the-experts', current:  location && location.hash.includes('ask'), useHashLink: true }, 
        { name: 'Register', href: '/registration/', current: location && location.pathname.includes('registration') }
      ]

      if(eventEnded || hasEventEnded) {
        navigation.pop()
      }

    return (
        <div className="bg-brand-500 sticky top-0 z-10"> 
        <div className="max-w-7xl relative mx-auto px-4 md:px-6">
         <nav className="flex space-x-2 lg:space-x-8 justify-between md:justify-start" aria-label="Global">
        
              {navigation.map((item) => {
         
                  return (
                    <Link
                    key={item.name}
                    to={item.href}
                    className={classNames(
                      item.current ? 'text-white border-white font-semibold' : 'text-white hover:border-white ',
                      'border-b-4 border-transparent py-4 px-3 inline-flex items-center text-sm md:text-base font-medium focus:outline-none focus:ring-1 focus:ring-offset-2 focus:ring-brand-500'
                    )}
                    aria-current={item.current ? 'page' : undefined}
                  >
                    {item.name}
                  </Link>
                  )
                })}
            </nav>
            </div>
        </div>
    );
}

export default Navbar;