import * as React from "react"
import { Link } from "gatsby"
import Layout from "../components/layout";
import Navbar from "../components/Navbar";
import config from "../config"
import Logo from "../components/Logo";
import Footer from "../components/Footer"

// markup
const UnsupportedBrowser = ({location}) => {
  return (
      <>
      <title>{config.APP.APP_NAME} | CalPERS</title>
      <div className="max-w-7xl mx-auto px-4 md:px-6" id="top">
      <div className="py-4 flex justify-between items-center">
      <div className="w-80">
                <Link
                  to={"/account/dashboard"}
                  className="block focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-brand-500"
                >
                  <Logo fill="#00659e" includeTitle={true} />
                </Link>
              </div>
       
      </div>
      </div>

      <div className="bg-brand-500 sticky top-0 z-10 "> 
        <div className="max-w-7xl relative mx-auto px-4 md:px-6">
         <nav className="flex space-x-2 lg:space-x-8 h-8" aria-label="Global">
        
              <span></span>
            </nav>
            </div>
        </div>


      <div className="overflow-hidden">
        <div className="h-auto bg-cover relative bg-brand-900">
          <div className="relative bg-gray-100">
            <div
              className={`max-w-7xl py-8 carousel-content-container flex flex-wrap justify-between relative mx-auto px-4 md:px-6 pb-64`}
            >
              <div className="w-full py-2 md:py-4 md:pr-8 lg:pr-32 relative ">
                <div className="max-w-2xl">
                  <h1 className="text-center md:text-left text-black relative mb-2 text-2xl md:text-4xl font-bold leading-10">
                  Unsupported Browser
                  </h1>
                  <p className="text-center md:text-left block font-medium my-2 text-base text-gray-700 ">
                  We noticed you're trying to access our {config.APP.APP_NAME} website using an unsupported browser. We recommend using Chrome or Firefox for an optimum viewing experience.
                  </p>
                  <p className="text-center md:text-left block font-medium my-2 text-base text-gray-700 ">
                  Copy and paste this link into a supported browser such as Chrome or Firefox: {config.APP.URL}
                  </p>
                </div>
              </div>
            </div>
          </div>
        </div>
        </div>
        <Footer/>
        </>

      
  )
}

export default UnsupportedBrowser
